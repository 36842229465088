<template>
  <CabinetPage :preloader="preloader">
    <div class="mx-cabinet-page-header">
      <router-link :to="{name: 'company.show', params: {id: $route.params.company_id}}" class="btn btn-outline-secondary">
        <ph-caret-left :size="22" />
      </router-link>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="mx-form-header">
          <h4 class="mb-3">{{ $t('company.person.new_person') }}</h4>
          <a href="#" class="btn btn-danger" @click.prevent="deletePersonModal(true)">
            <ph-trash-simple :size="22" />
          </a>
        </div>
        <form class="mx-form">
          <div class="mx-modal-form">
            <div class="mb-3">
              <div class="mx-modal-form__element">
                <label class="form-label">{{ company.name }}</label>
              </div>
              <div class="mx-modal-form__element" v-for="element in form_elements">
                <label class="form-label">{{ $t(`company.person.form.${element}`) }}</label>
                <input type="text" class="form-control" v-model="form[element]">
                <div v-if="errors[element]" class="invalid-feedback">
                  {{ $t(`company.errors.${getErrorWord(errors[element][0])}`) }}
                </div>
              </div>

              <div class="mx-modal-form__element">
                <label class="form-label">{{ $t(`company.person.form.birthday`) }}</label>
                <input type="text" class="form-control" v-mask="'99.99.9999'" v-model="form.birthday" placeholder="TT.MM.JJJJ">
                <div v-if="errors['birthday']" class="invalid-feedback">
                  {{ $t(`company.errors.${getErrorWord(errors['birthday'][0])}`) }}
                </div>
              </div>

              <div class="mx-modal-form__element">
                <label class="form-label">{{ $t(`company.person.form.gender`) }}</label>
                <b-form-select v-model="form.gender" :options="genders" class="form-control"></b-form-select>
              </div>

              <div class="mx-modal-form__element" style="margin-top: 20px;">
                <label class="mx-modal-form-switch">
                  <input type="checkbox" class="mx-modal-form-switch__input" v-model="form.put_through">
                  <span class="mx-modal-form-switch__slider mx-round"></span>
                </label>
                <label class="form-label">{{ $t(`company.person.form.put_through`) }}</label>
              </div>

            </div>
          </div>
          <div class="mx-form-buttons">
            <router-link :disabled="button_disabled" tag="button" :to="{name: 'company.show', params: {id: $route.params.company_id}}" class="btn btn-peepz-default">
              {{ $t('company.abort') }}
            </router-link>
            <button :disabled="button_disabled" @click="save" type="button" class="btn btn-peepz-success">{{ $t('company.save') }}</button>
          </div>
        </form>
      </div>
    </div>

    <b-modal ref="deleteAddress" centered hide-header hide-footer no-close-on-backdrop no-close-on-esc>
      <div class="mx-modal-prompt">
        <h3 class="mx-modal-prompt__title">{{ $t('company.prompt.person.title') }}</h3>
        <strong class="mx-modal-prompt__subtitle">{{ $t('company.prompt.person.subtitle') }}</strong>
        <div class="mx-modal-prompt__buttons">
          <button type="button" :disabled="button_disabled" class="btn btn-peepz-danger" @click="personDelete">{{ $t('company.delete') }}</button>
          <button type="button" :disabled="button_disabled" class="btn btn-peepz-default" @click="deletePersonModal(false)">{{ $t('company.abort') }}</button>
        </div>
      </div>
    </b-modal>

  </CabinetPage>
</template>

<script>

import CabinetPage from "@/components/main/CabinetPage";

import {
  PhPlus,
  PhLockLaminated,
  PhCaretLeft,
  PhTrashSimple
} from "phosphor-vue";

export default {
  components: {CabinetPage, PhPlus, PhLockLaminated, PhCaretLeft, PhTrashSimple},
  data() {
    return {
      button_disabled: false,
      preloader: true,
      company: {
        'id': null,
        'name': ''
      },
      form_elements: [
        'position',
        'first_name',
        'last_name',
        'phone',
        'email'
      ],
      genders: [
        { value: null, text: this.$t('company.person.form.gender_select') },
        { value: 'female', text: this.$t('user.gender.female') },
        { value: 'male', text: this.$t('user.gender.male') },
        { value: 'divers', text: this.$t('user.gender.divers') },
        { value: 'indefinite', text: this.$t('user.gender.indefinite') },
      ],
      form: {
        id: null,
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        birthday: '',
        position: '',
        put_through: 0,
        gender: null,
      },
      errors: [],
    }
  },
  mounted() {
    this.getCompany()
  },
  methods: {
    deletePersonModal(bool) {
      if (bool) {
        this.$refs['deleteAddress'].show()
      } else {
        this.$refs['deleteAddress'].hide()
      }
    },
    personDelete() {
      this.$store.dispatch('personDelete', this.form.id).then(() => {
        this.$router.replace({
          name: 'company.show', params: {id: this.form.company_id}
        })
      })
    },
    getCompany() {
      this.$store.dispatch('companies').then(() => {
        const company = this.$store.getters.companies.find(company => company.id === parseInt(this.$route.params.company_id))
        this.company.name = company.company_name
        this.form.company_id = company.id

        const person = company.persons.find(person => person.id === this.$route.params.id)

        this.form.id = person.id
        this.form.first_name = person.first_name
        this.form.last_name = person.last_name
        this.form.birthday = person.birthday
        this.form.email = person.email
        this.form.phone = person.phone
        this.form.gender = person.gender
        this.form.position = person.position
        this.form.put_through = parseInt(person.put_through)

        this.preloader = false
      })
    },
    save() {
      this.button_disabled = true;
      this.errors = []

      this.form.gender = this.form.gender === null ? 'female' : this.form.gender

      this.$store.dispatch('personEdit', this.form).then(() => {
        this.$router.replace({
          name: 'company.show', params: {id: this.form.company_id}
        })
      }).catch(error => {
        this.button_disabled = false;

        switch (error.response.status) {
          case 422:
            this.errors = error.response.data;
            break;
        }
      })
    },
    getErrorWord(str) {
      return str.match(/\b(required|number|valid)\b/g)[0]
    }
  }
}
</script>
